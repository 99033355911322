import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { UsersService } from '../services/users.service';
//import bankName from '../data/bankName.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wallet-withdraw-detail',
  templateUrl: './wallet-withdraw-detail.component.html',
  styleUrls: ['./wallet-withdraw-detail.component.scss']
})
export class WalletWithdrawDetailComponent implements OnInit {
  show: any;
  banktype: any;
  withdrawmethod: any;
  addwithdraw: any;
  bankName: any;
  withdrawlId: any;
  token: any;
  bankid: any;
  otpButtonDisable: any;
  BankForm: FormGroup;
  upiForm: FormGroup;
  upiIdForm: FormGroup;
  deletebankform: FormGroup;
  modalRef: BsModalRef;
  userdatak:any;
  withdrawmethodlist:any;
  showCountdown: boolean = false;
  countdown: number;
  constructor(
    private _location: Location,
    private toastr: ToastrService,
    private usersService: UsersService,
    private modalService: BsModalService,
    private fb: FormBuilder,
  ) {
    this.banktype = 'Bank';
    this.otpButtonDisable = false;


  }

  ngOnInit(): void {
    this.userdatak =  JSON.parse(sessionStorage.getItem('userDetails'));
    this.token = sessionStorage.getItem("token")
    if(this.userdatak){
      this.getwithdral();
      this.createFrom();
      this.createupiId();
      this.createupiform();
      this.deleteform();
      this.getbanklist();
    }
    
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }

  getwithdral() {
    const data={
      user_id:this.userdatak.details._id,
    }
    this.usersService.walletPost("user-withdraw-method" , data).subscribe((response: any) => {
      this.withdrawmethod = response.data;
      console.log(this.withdrawmethod);
    })
  }
  getbanklist() {
    const data={
      user_id:this.userdatak.details._id,
    }
    this.usersService.walletPost("get-bank-list" , data).subscribe((response: any) => {
      this.bankName = response.data;
      console.log(this.bankName);
    })
  }

  addbank(bankadd: TemplateRef<any>, detailes) {
    this.banktype = detailes.type;
    this.withdrawlId = detailes._id;
    this.bankName = this.bankName;
    this.modalRef = this.modalService.show(
      bankadd,
      Object.assign({}, { class: 'bankform modal-lg' })
    );
  }

  formdetaile(){
    this.createFrom();
    this.createupiform();
    this.createupiId();
  }

  createupiform() {
    this.upiForm = this.fb.group({
      upino: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      name: ['', [Validators.required]],
      otp: ['', [Validators.required]],
    })
  }
  createupiId(){
    this.upiIdForm = this.fb.group({
      upiId: ['', [Validators.required, Validators.pattern(/^[\w.-]+@[\w.-]+$/)]],
      upiname: ['', [Validators.required]],
      upiotp: ['', [Validators.required]],
    })
  }
  
  deleteform() {
    this.deletebankform = this.fb.group({
      otp: ['', [Validators.required]],
    })
  }

  createFrom() {
    this.BankForm = this.fb.group({
      bankName: ['', [Validators.required]],
      ifsc: ['', [Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/), Validators.maxLength(11)]],
      accnumber: ['', [Validators.required, Validators.pattern(/^\d{10,16}$/), Validators.minLength(10), Validators.maxLength(16)]],
      name: ['', [Validators.required]],
      otp: ['', [Validators.required]],
    })
  }

  
  startCountdown() {
    this.showCountdown = true;
    this.countdown = 120; // Initial countdown value
    this.doCountdown();
  }
  
  doCountdown() {
    setTimeout(() => {
      this.countdown--;
      if (this.countdown > 0) {
        this.doCountdown();
      } else {
        this.showCountdown = false;
      }
    }, 1000); // Update every second
  }
  Addbank() {
  
    if (!this.BankForm.value.bankName) {
      return;
    } else if (!this.BankForm.value.accnumber) {
      this.toastr.error(' Please enter Acoount Number is Required!'); 
      return;
    } else if (!this.BankForm.value.ifsc) {
      return;
    } else if (!this.BankForm.value.name) {
      return;
    } else if (!this.banktype) {
      return;
    } else if(this.BankForm.get('ifsc').errors?.pattern){
      return;
    }
    else if(this.BankForm.get('accnumber').errors?.pattern){
      return;
    }
    else if (!this.BankForm.value.otp) {
      // alert(1)
      this.otpButtonDisable = true;
      const data={
        user_id:this.userdatak.details._id,
      }
      this.usersService.walletPost('createOTP', data).subscribe((result: any) => {
       // console.log(result);
       if(result.success===true){
        this.startCountdown();
       }
       else{

       }
      
      })
      return;
    } else {
      // alert(2)
     
      var data = {
        accnumber: this.BankForm.value.accnumber,
        bankName: this.BankForm.value.bankName,
        user_id:this.userdatak.details._id,
        ifsc: this.BankForm.value.ifsc,
        name: this.BankForm.value.name,
        type: 'Bank',
        upi: "",
        withdrawlId: this.withdrawlId,
        otp : this.BankForm.value.otp,
      }
      // console.log(data);
      this.usersService.walletPost('create-user-withdraw-method', data).subscribe((result: any) => {
        this.modalRef.hide();
        this.BankForm.reset();
        this.getwithdral();
        this.otpButtonDisable = false;
        if(result.success==true){
          this.toastr.success(result.message);
         
        }else{
          this.toastr.error(result.message);
        }
        
      }) // console.log(result);

      //   

    }
   }
  

  Addupi() {
    if (!this.upiForm.value.name) {
      this.toastr.error(' Please Enter Name!');
      return;
    } else if (!this.upiForm.value.upino) {
      this.toastr.error(' Please enter Upi Number Required!');
      return;
    }else if(this.upiForm.get('upino').invalid){
      this.toastr.error(' Please enter 10-digit Upi number Required!');
      return;
    } else if (!this.upiForm.value.otp) {
    //  alert(1)
      this.otpButtonDisable = true;
      const data={
        user_id:this.userdatak.details._id,
      }
      this.usersService.walletPost('createOTP', data).subscribe((result: any) => {
        if(result.success===true){
          this.startCountdown();
         }
         else{
  
         }
      })
      return;
    } else {
      var data = {
        accnumber: '',
        bankName: '',
        ifsc: '',
        name: this.upiForm.value.name,
        otp: this.upiForm.value.otp,
        // type: 'bank',
        user_id:this.userdatak.details._id,
        upi: this.upiForm.value.upino,
        withdrawlId: this.withdrawlId,
      }
       console.log(data);
      this.usersService.walletPost('create-user-withdraw-method' , data).subscribe((result: any) => {
        // console.log(result);
        this.getwithdral();
        // this.toastr.success(result.message);
        this.modalRef.hide();
        this.otpButtonDisable = false;
        this.upiForm.reset();
        if(result.success==true){
          this.toastr.success(result.message);
        }else{
          this.toastr.error(result.message);
        }
      })
    }
  }

  Addnewupi() {
    if (!this.upiIdForm.value.upiname) {
      this.toastr.error(' Please Enter Name!');
      return;
    } else if (!this.upiIdForm.value.upiId) {
      this.toastr.error(' Please enter UPI Id is Required!');
      return;
    }else if(this.upiIdForm.get('upiId').invalid){
      this.toastr.error(' Please UPI Id is Required!');
      return;
    } else if (!this.upiIdForm.value.upiotp) {
    //  alert(1)
      this.otpButtonDisable = true;
      const data={
        user_id:this.userdatak.details._id,
      }
      this.usersService.walletPost('createOTP', data).subscribe((result: any) => {
        if(result.success===true){
          this.startCountdown();
         }
         else{
  
         }
      })
      return;
    } else {
      var data = {
        accnumber: '',
        bankName: '',
        ifsc: '',
        name: this.upiIdForm.value.upiname,
        otp: this.upiIdForm.value.upiotp,
        // type: 'bank',
        user_id:this.userdatak.details._id,
        upi: this.upiIdForm.value.upiId,
        withdrawlId: this.withdrawlId,
      }
       console.log(data);
      this.usersService.walletPost('create-user-withdraw-method' , data).subscribe((result: any) => {
        // console.log(result);
        this.getwithdral();
        // this.toastr.success(result.message);
        this.modalRef.hide();
        this.otpButtonDisable = false;
        this.upiForm.reset();
        if(result.success==true){
          this.toastr.success(result.message);
         
        }else{
          this.toastr.error(result.message);
        }
      })
    }
  }

  getotp(){
    this.otpButtonDisable = true;
    const data={
      user_id:this.userdatak.details._id,
    }
    this.usersService.walletPost('createOTP', data).subscribe((result: any) => {
      console.log(result);
    })
  }

  openmodel(success: TemplateRef<any>, id) {
    // console.log(this.detaile);
    this.bankid = id;
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  opensubmitformmodel(submitform: TemplateRef<any>, id) {
    // console.log(this.detaile);
    this.bankid = id;
    this.modalRef = this.modalService.show(
      submitform,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  deletebank(bankid) {
    const data = {user_id:this.userdatak.details._id,id: bankid ,otp:this.deletebankform.value.otp}
    this.usersService.walletPost('delete-withdraw-method', data).subscribe((result: any) => {
      // console.log(result);
      // this.toastr.success(result.message);
      this.getwithdral();
      this.modalRef.hide();
        this.otpButtonDisable = false;
        this.deletebankform.reset();
        if(result.success==true){
          this.toastr.success(result.message);
        }else{
          this.toastr.error(result.message);
        }
    })
  }

}
