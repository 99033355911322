import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UsersService } from "../services/users.service";
import { Socket } from "ngx-socket-io";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  varifyOtpForm: FormGroup;
  loginForm: FormGroup;
  registerButtonDisable = false;
  otpButtonDisable = false;
  submitted = false;
  private clickTimeout: any = null;
  public showPassword: boolean;
  a: any;
  logo: string;
  step: number = 1;
  url_youtube: any;
  url_insta: any;
  url_telegram: any;
  username: any;
  countdown: number;
  showCountdown: boolean = false;
  code: any;
  codesataus:boolean;
  userdata:any;
  constructor(
    private router: Router,
    public httpClient: UsersService,
    private fb: FormBuilder,
    private socket: Socket,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { 
    this.route.paramMap.subscribe(param=>{
      this.code=param.get('code');
      if(this.code=='nocode'){
        this.codesataus =false;
      }else{
        this.codesataus =true;
        
      }
      });
      
  }

  ngOnInit(): void { 
    this.changeIcon();
    this.createFrom();
    this.createloginFrom();
  }

  async findHostName() {
    return window.location.hostname;
  }

  async changeIcon() {
    const hostname = await this.findHostName();
    const splithostname = hostname.split(".");
    this.logo = splithostname[0];
    this.setManagerId();
    this.setManager();
  }

  async createloginFrom() {
    
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      manager: ''
    })
  }

 async  createFrom() {
   
    this.registerForm = this.fb.group({
     // type: "Subadmin",
      user_id:'',
      manager: '',
    //  typeId: "",
      phone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      username: ["",[Validators.required]],
     // name: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(4)]],
     referal_code: "",
    });

    this.varifyOtpForm = this.fb.group({
//type: "Subadmin",
//typeId: "",
      phone: ["", [Validators.required]],
      otp: ["", [Validators.required]],
      manager: '',
    });
  }

  setManagerId() {
     if (this.logo === "777exch") {
      this.registerForm.patchValue({ user_id: "66aca3f1cb0d150361ca91ba" });
      this.varifyOtpForm.patchValue({ user_id: "66aca3f1cb0d150361ca91ba" });
    } 
    else if (this.logo === "clubprt") {
      this.registerForm.patchValue({ user_id: "62b013cd0f828a47b35a6307" });
      this.varifyOtpForm.patchValue({ user_id: "62b013cd0f828a47b35a6307" });
    } else if (this.logo === "ferrariclubb") {
      this.registerForm.patchValue({ user_id: "62a9c86c54c46754cd46ce61" });
      this.varifyOtpForm.patchValue({ user_id: "62a9c86c54c46754cd46ce61" });
    } else if (this.logo === "aobexch") {
      this.registerForm.patchValue({ user_id: "65f295066517fadee8cb89d5" });
      this.varifyOtpForm.patchValue({ user_id: "65f295066517fadee8cb89d5" });
    } else if (this.logo === "dlexch") {
      this.registerForm.patchValue({ user_id: "62b9951b9ea29a1c11fcbf73" });
      this.varifyOtpForm.patchValue({ user_id: "62b9951b9ea29a1c11fcbf73" });
    } else if (this.logo === "osgexch365") {
      this.registerForm.patchValue({ user_id: "655b60970b979d9320dcebdd" });
      this.varifyOtpForm.patchValue({ user_id: "655b60970b979d9320dcebdd" });
    } else if (this.logo === "fairbets247") {
      this.registerForm.patchValue({ user_id: "631195e7d84105a6457fd88e" });
      this.varifyOtpForm.patchValue({ user_id: "631195e7d84105a6457fd88e" });
    } else if (this.logo === "khelomereyar") {
      this.registerForm.patchValue({ user_id: "65d72453885b50bfb93eb591" });
      this.varifyOtpForm.patchValue({ user_id: "65d72453885b50bfb93eb591" });
    } else if (
      this.logo === "clubosg") {
      if(this.logo === "clubosg"){
        this.url_youtube="https://youtube.com/@clubosg?si=seaAPXhS_KifDCyu";
        this.url_insta="https://instagram.com/clubosg_official?igshid=OGQ5ZDc2ODk2ZA==";
        this.url_telegram="https://t.me/osgclub";
      }
      this.registerForm.patchValue({ user_id: "6298db691200067e21a6809a" });
      this.varifyOtpForm.patchValue({ user_id: "6298db691200067e21a6809a" });
    }else if (this.logo === "clublucky7") {
      this.registerForm.patchValue({ user_id: "64631860977b92c664eeb2c1" });
      this.varifyOtpForm.patchValue({ user_id: "64631860977b92c664eeb2c1" });
    }
     else if (this.logo === "paisaexch") {
      this.registerForm.patchValue({ user_id: "63c837b71543274169804ee4" });
      this.varifyOtpForm.patchValue({ user_id: "63c837b71543274169804ee4" });
    } else if (this.logo === "boomboombook") {
      this.registerForm.patchValue({ user_id: "649adb3ac7aa589207a040a8" });
      this.varifyOtpForm.patchValue({ user_id: "649adb3ac7aa589207a040a8" });
    }else if (this.logo === "telegrambook") {
      this.registerForm.patchValue({ user_id: "65e03c84fa5de4d69a0aa43a" });
      this.varifyOtpForm.patchValue({ user_id: "65e03c84fa5de4d69a0aa43a" });
    } else {
      this.registerForm.patchValue({ user_id: "66aca3f1cb0d150361ca91ba" });
      this.varifyOtpForm.patchValue({ user_id: "66aca3f1cb0d150361ca91ba" });
    }
     
  }

  setManager(){
    if(this.logo==='777exch')
      {
        this.loginForm.patchValue({manager: 'EXCH'});
        this.registerForm.patchValue({ manager: 'EXCH' });
        this.varifyOtpForm.patchValue({manager: 'EXCH'});
      }else if(this.logo==='clubprt')
    {
      this.loginForm.patchValue({manager: 'PRTCLUB'});
      this.registerForm.patchValue({ manager: 'PRTCLUB' });
      this.varifyOtpForm.patchValue({manager: 'PRTCLUB'});
    }else if(this.logo==='ferrariclubb'){
      this.loginForm.patchValue({manager: 'FERRARICLUB'});
      this.registerForm.patchValue({ manager: 'FERRARICLUB' });
      this.varifyOtpForm.patchValue({manager: 'FERRARICLUB'});
    }else if(this.logo==='boomboombook'){
      this.loginForm.patchValue({manager: 'BOOM'});
      this.registerForm.patchValue({ manager: 'BOOM' });
      this.varifyOtpForm.patchValue({manager: 'BOOM'});
    }
    else if(this.logo==='aobexch'){
      this.loginForm.patchValue({manager: 'AOBAOB'});
      this.registerForm.patchValue({ manager: 'AOBAOB' });
      this.varifyOtpForm.patchValue({manager: 'AOBAOB'});
    }
    else if(this.logo==='dlexch'){
      this.loginForm.patchValue({manager: 'DLCLUB'});
      this.registerForm.patchValue({ manager: 'DLCLUB' });
      this.varifyOtpForm.patchValue({manager: 'DLCLUB'});
    }
    else if(this.logo==='fairbets247'){
      this.loginForm.patchValue({manager: 'FAIRBET'});
      this.registerForm.patchValue({ manager: 'FAIRBET' });
      this.varifyOtpForm.patchValue({manager: 'FAIRBET'});
    }
    else if(this.logo==='paisaexch'){
      this.loginForm.patchValue({manager: 'OSG203'});
      this.registerForm.patchValue({ manager: 'OSG203' });
      this.varifyOtpForm.patchValue({manager: 'OSG203'});
    }
    else if(this.logo==='clubosg'){
      this.loginForm.patchValue({manager: 'OSGCLUB'});
      this.registerForm.patchValue({ manager: 'OSGCLUB' });
      this.varifyOtpForm.patchValue({manager: 'OSGCLUB'});
    }
    else if(this.logo==='clublucky7'){
      this.loginForm.patchValue({manager: 'PRTLUCKY'});
      this.registerForm.patchValue({ manager: 'PRTLUCKY' });
      this.varifyOtpForm.patchValue({manager: 'PRTLUCKY'});
    }
    else if(this.logo==='telegrambook'){
      this.loginForm.patchValue({manager: 'TELE'});
      this.registerForm.patchValue({ manager: 'TELE' });
      this.varifyOtpForm.patchValue({manager: 'TELE'});
    }
    else{
      this.loginForm.patchValue({manager: 'EXCH'});
      this.registerForm.patchValue({ manager: 'EXCH' });
      this.varifyOtpForm.patchValue({manager: 'EXCH'});
    }
  }

  async onRegisterClick() {
    this.registerButtonDisable = true;
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      this.setClickTimeout(() => this.handleSingleLoginClick());
    }
  }

  async onVerifyOtpClick() {
    this.otpButtonDisable = true;
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      this.setClickTimeout(() => this.handleSingleVerifyClick());
    }
  }

  get f() {
    return this.registerForm.controls;
  }

  remveSpace(event) {
    this.username = event.target.value.replace(/\s+/g, "");
    this.registerForm.value.username = event.target.value.replace(/\s+/g, "");
  }

  async handleSingleLoginClick() {
    //The actual action that should be performed on click
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.registerButtonDisable = false;
      this.toastr.error("field is invalid !");
      return;
    }
    // if (this.registerForm.value.username == "") {
    //   this.registerButtonDisable = false;
    //   this.toastr.error("username is required !");
    //   return;
    // }
    
    const a = this.registerForm.value.phone;
    this.registerForm.value.phone = a;
    this.varifyOtpForm.patchValue({ phone: this.registerForm.value.phone });
    if(this.code=='nocode'){
      // var code = this.registerForm.value.affilate_code;
      this.registerForm.value.affilate_code='';
    }else{
      var code = this.code;
      var codeupper = code.toUpperCase();
    this.registerForm.value.affilate_code = codeupper;
    }
    const data = this.registerForm.value;

   // console.log(data);
    this.httpClient
      .walletPost("register", data)
      .subscribe((response: any) => {
           
        if (response.success) {
          this.toastr.success(response.message, "Success!");
          this.startCountdown();
          this.step = 2;
        } else {
          this.toastr.error(response.message, "Error!");
          this.submitted = false;
          this.registerButtonDisable = false;
        }
      });
  }
  public resendhandleSingleLoginClick() {
    this.startCountdown();
    //The actual action that should be performed on click
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.registerButtonDisable = false;
      this.toastr.error("field is invalid !");
      return;
    }

    if (this.logo === "telegrambook") {
      if (this.registerForm.value.username == "") {
        this.registerButtonDisable = false;
        this.toastr.error("username is required !");
        return;
      }

      this.registerForm.value.username = this.username.toUpperCase();
    }

    const a = this.registerForm.value.phone;
    this.registerForm.value.phone = a;
    this.varifyOtpForm.patchValue({ phone: this.registerForm.value.phone });

    const registerData = this.registerForm.value;
     console.log(registerData);

    this.httpClient
      .walletPost("resendOtp", registerData)
      .subscribe((response: any) => {
        console.log(response);
        
        if (response.success || response.message === "User already registered") {
           this.toastr.success(response.message, "Success!");
          this.step = 2;
        } else {
          this.toastr.error(response.message, "Error!");
          this.submitted = false;
          this.registerButtonDisable = false;
        }
      });
  }

  public handleSingleVerifyClick() {

    this.submitted = true;
    if (this.varifyOtpForm.invalid) {
      this.otpButtonDisable = false;
      return;
    }

    const otpData = this.varifyOtpForm.value;
    this.httpClient
      .walletPost("verifyOtp", otpData)
      .subscribe((response: any) => {
        if (response.success === true) {
          this.toastr.success(response.message, "Success!");
          this.loginForm.patchValue({ password: this.registerForm.value.password });
          this.loginForm.patchValue({ username: response.data.username });
          this.redirectverify();
        } else {
          this.toastr.error(response.message, "Error!");
          this.submitted = false;
          this.otpButtonDisable = false;
        }
      });
  }

  public setClickTimeout(callback: any) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  redirectverify() {
    // this.router.navigate(["/wallet"]);
    if (this.loginForm) {
      this.handleSingleLoginbutton();

    }
  }

  public handleSingleLoginbutton() {
    //The actual action that should be performed on click      
    this.submitted = true;
    if (this.loginForm.invalid) {
      // this.loginButtonDisable=false;
      return;
    }

    const loginData = { user: this.loginForm.value }
     console.log("testinggg",loginData);

    this.socket.emit('login-wallet', loginData);

    this.socket.on('login-error', (function (o: any) {
      this.toastFunction(o.message);
      this.loginButtonDisable = false;
    }).bind(this));
    this.socket.on('get-login-status', (function (error: any) {
      // Unable to log in
      this.toastFunction(error.message);
      this.loginButtonDisable = false;
    }).bind(this));
    this.socket.on('login-success', (function (data: any) {

      const output = data.output;
      // console.log(output);


      if (output) {
        // Todo: validate output 
        this.loginButtonDisable = false;
        sessionStorage.setItem('loginStatus', 'true');
        sessionStorage.setItem('userDetails', JSON.stringify(output));
        this.redirectToWallet();
      }

    }).bind(this));

  }

  async redirectToWallet() {
    var acctoken = await sessionStorage.getItem('loginStatus');

    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['/dashboard']);
    }
  }
  toastFunction(msg: any) {
    this.toastr.error(msg + ' !');
  }
  startCountdown() {
    this.showCountdown = true;
    this.countdown = 120; // Initial countdown value
    this.doCountdown();
  }
  
  doCountdown() {
    setTimeout(() => {
      this.countdown--;
      if (this.countdown > 0) {
        this.doCountdown();
      } else {
        this.showCountdown = false;
      }
    }, 1000); // Update every second
  }
}
